import rootConfigAlpha from '../../config/alp.json';
import rootConfigProduction from '../../config/prd.json';
import rootConfigStaging from '../../config/stg.json';
function initializeRootConfig() {
    const hostname = window.location.hostname;
    const alphaHostnames = ['localhost', 'alpha.uipath.com', 'portal.alpha.uipath.com'];
    const stagingHostnames = ['staging.uipath.com', 'portal.staging.uipath.com'];
    const productionHostnames = ['cloud.uipath.com', 'portal.uipath.com'];
    if (alphaHostnames.includes(hostname)) {
        return rootConfigAlpha;
    }
    if (stagingHostnames.includes(hostname)) {
        return rootConfigStaging;
    }
    if (productionHostnames.includes(hostname)) {
        return rootConfigProduction;
    }
    // TODO: handle gov environments
    throw new Error(`Unknown environment for hostname: ${hostname}`);
}
window.__ROOT_CONFIG_ENV__ = initializeRootConfig();
